/**
 * @license
 * MyFonts Webfont Build ID 4136235, 2021-08-04T05:45:01-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: SofiaPro-UltraLight by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/ultra-light/
 *
 * Webfont: SofiaPro-UltraLightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/ultra-light-italic/
 *
 * Webfont: SofiaPro-ExtraLight by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/extra-light/
 *
 * Webfont: SofiaPro-ExtraLightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/extra-light-italic/
 *
 * Webfont: SofiaPro-Light by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/light/
 *
 * Webfont: SofiaPro-Lightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/light-italic/
 *
 * Webfont: SofiaPro-Regular by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular/
 *
 * Webfont: SofiaPro-Regularitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular-italic/
 *
 * Webfont: SofiaPro-Medium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 *
 * Webfont: SofiaPro-Mediumitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium-italic/
 *
 * Webfont: SofiaPro-SemiBold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/semi-bold/
 *
 * Webfont: SofiaPro-SemiBolditalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/semi-bold-italic/
 *
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 *
 * Webfont: SofiaPro-Bolditalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold-italic/
 *
 * Webfont: SofiaPro-Black by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/black/
 *
 * Webfont: SofiaPro-Blackitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/black-italic/
 *
 *
 * Webfonts copyright: Copyright © Olivier Gourvat - Mostardesign Type Foundry, 2020. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  /*SofiaPro-Blackitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 800;
  font-style: 'italic';
  src: url('../fonts/SofiaProBlackitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProBlackitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-UltraLightitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 100;
  font-style: 'italic';
  src: url('../fonts/SofiaProUltraLightitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProUltraLightitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-ExtraLight*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 200;
  font-style: 'normal';
  src: url('../fonts/SofiaProExtraLight/font.woff2') format('woff2'),
    url('../fonts/SofiaProExtraLight/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-Bolditalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 700;
  font-style: 'italic';
  src: url('../fonts/SofiaProBolditalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProBolditalic/font.woff') format('woff');
}

@font-face {
  /*SofiaPro-Light*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 300;
  font-style: 'normal';
  src: url('../fonts/SofiaProLight/font.woff2') format('woff2'),
    url('../fonts/SofiaProLight/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-UltraLight*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 200;
  font-style: 'normal';
  src: url('../fonts/SofiaProUltraLight/font.woff2') format('woff2'),
    url('../fonts/SofiaProUltraLight/font.woff') format('woff');
}

@font-face {
  /*SofiaPro-Medium*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 500;
  font-style: 'normal';
  src: url('../fonts/SofiaProMedium/font.woff2') format('woff2'),
    url('../fonts/SofiaProMedium/font.woff') format('woff');
}

@font-face {
  /*SofiaPro-Mediumitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 500;
  font-style: 'italic';
  src: url('../fonts/SofiaProMediumitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProMediumitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-ExtraLightitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 200;
  font-style: 'italic';
  src: url('../fonts/SofiaProExtraLightitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProExtraLightitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-Black*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 800;
  font-style: 'normal';
  src: url('../fonts/SofiaProBlack/font.woff2') format('woff2'),
    url('../fonts/SofiaProBlack/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-Lightitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 300;
  font-style: 'italic';
  src: url('../fonts/SofiaProLightitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProLightitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-SemiBold*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 600;
  font-style: 'normal';
  src: url('../fonts/SofiaProSemiBold/font.woff2') format('woff2'),
    url('../fonts/SofiaProSemiBold/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-Regularitalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 400;
  font-style: 'italic';
  src: url('../fonts/SofiaProRegularitalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProRegularitalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-SemiBolditalic*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 600;
  font-style: 'italic';
  src: url('../fonts/SofiaProSemiBolditalic/font.woff2') format('woff2'),
    url('../fonts/SofiaProSemiBolditalic/font.woff') format('woff');
}
@font-face {
  /*SofiaPro-Regular*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 400;
  font-style: 'normal';
  src: url('../fonts/SofiaProRegular/font.woff2') format('woff2'),
    url('../fonts/SofiaProRegular/font.woff') format('woff');
}

@font-face {
  /*SofiaPro-Bold*/
  font-family: 'SofiaPro';
  font-display: swap;
  font-weight: 700;
  font-style: 'normal';
  src: url('../fonts/SofiaProBold/font.woff2') format('woff2'),
    url('../fonts/SofiaProBold/font.woff') format('woff');
}
