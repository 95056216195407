@import './_fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.flickity-slider {
  height: 100%;
  display: flex;
}
.flickity-button {
  height: 13px;
  width: 13px;
  position: absolute;
  &:disabled {
    opacity: 0.1;
  }
}
.flickity-prev-next-button {
  bottom: 10px;
}
.flickity-prev-next-button.previous {
  left: 0px;
}
.flickity-prev-next-button.next {
  left: 20px;
}

.flickity-page-dots {
  height: 3px;
  position: absolute;
  left: 50px;
  bottom: 29px;
  .dot {
    display: inline-block;
    width: 10px;
    height: 3px;
    background: #333;
    border-radius: 0;
    opacity: 0.25;
    cursor: pointer;
    &.is-selected {
      opacity: 1;
    }
  }
}

.text-help {
  @apply text-sm-fluid text-red-600 mt-5;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: stretch;
}

html,
body {
  height: 100%;
}

[type='date'],
[type='datetime-local'],
[type='time'] {
  font-family: ui-sans-serif, system-ui;
}

#__next {
  flex: 1;
  flex-direction: column;
  display: flex;
}
body {
  display: flex;
  flex-direction: column;
}

.delay-0 {
  animation-delay: 0ms !important;
}
.delay-1 {
  animation-delay: 600ms !important;
}
.delay-2 {
  animation-delay: 900ms !important;
}
.delay-3 {
  animation-delay: 1200ms !important;
}
.delay-4 {
  animation-delay: 1500ms !important;
}

.delay-fast-0 {
  animation-delay: 0ms !important;
}
.delay-fast-1 {
  animation-delay: 100ms !important;
}
.delay-fast-2 {
  animation-delay: 200ms !important;
}
.delay-fast-3 {
  animation-delay: 300ms !important;
}
.delay-fast-4 {
  animation-delay: 400ms !important;
}
.delay-fast-5 {
  animation-delay: 500ms !important;
}

.animation-delay-100 {
  animation-delay: 100ms;
}
.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}
.animation-delay-1000 {
  animation-delay: 1000ms;
}

.fade-and-grow {
  opacity: 1;
  transform: scale(1);
  animation: fade-and-grow 0.3s ease-out forwards;
  animation-delay: 4000ms;
}

.animate-leaderboard-in {
  opacity: 0;
  transform: scale(1);
  animation: animate-leaderboard-in 0.3s ease-out forwards;
  animation-delay: 4000ms;
}

.fade-in-out {
  opacity: 0;
  animation: fade-in-out 1s ease-in-out infinite;
  animation-play-state: initial;
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.2s ease-out forwards;
  animation-play-state: initial;
}
.fade-out {
  opacity: 0;
  animation: fade-out 1s;
  animation-play-state: initial;
  animation-fill-mode: both;
}
.fade-slide-in-fadeout {
  opacity: 0;
  animation-duration: 3s;
  animation-name: fade-slide-in-fadeout;
  animation-play-state: initial;
  transform: translateY(0);
}

@keyframes fade-in {
  from {
    opacity: 0!;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-slide-in-fadeout {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  25% {
    transform: translateY(0%);
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-and-grow {
  0% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(1.8);
  }
}

@keyframes animate-leaderboard-in {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading-animate {
  0% {
    background-color: rgba(146, 203, 247, 0);
  }

  50% {
    background-color: rgba(146, 203, 247, 0.8);
  }
  100% {
    background-color: rgba(146, 203, 247, 0);
  }
}

@keyframes loading-animate-orange {
  0% {
    background-color: rgba(234, 114, 42, 0);
  }

  50% {
    background-color: rgba(234, 114, 42, 0.5);
  }
  100% {
    background-color: rgba(234, 114, 42, 0);
  }
}

@keyframes loading-animate-hibiscus {
  0% {
    background-color: rgba(200, 47, 104, 0);
  }

  50% {
    background-color: rgba(200, 47, 104, 0.5);
  }
  100% {
    background-color: rgba(200, 47, 104, 0);
  }
}

@keyframes loading-animate-purple {
  0% {
    background-color: rgba(110, 57, 236, 0);
  }

  25% {
    background-color: rgba(110, 57, 236, 0.3);
  }
  50% {
    background-color: rgba(110, 57, 236, 0);
  }
  100% {
    background-color: rgba(110, 57, 236, 0);
  }
}
.animate-pulser {
  animation: loading-animate-purple 5s ease-in-out infinite;
}

/* @keyframes fade-in-out {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

@keyframes fade-in-out {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  70% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}

@page {
  size: auto;
  margin: 0;
}

/* navigation */
.overflow-container {
  height: 100vh !important;
  border-top: 80px solid rgba(41, 44, 62, var(--tw-bg-opacity));
}

.menu-list {
  &::after {
    content: '';
    display: block;
    height: calc(100vh - 80px) !important;
    width: 1px;
    position: absolute;
    top: 0;
    left: 80px;
    background-color: rgb(82, 89, 125);
  }
}

.icon {
  &::before {
    content: '';
    position: absolute;
    background: rgba(61, 67, 94, var(--tw-bg-opacity));
    top: 0;
    left: 0;
    width: 80px;
    height: 1000px;
    display: block;
    z-index: -1;
  }
}

.hamburger.open {
  span:nth-child(1) {
    transform: rotate(-45deg) translate(-8px, -2px);
  }

  span:nth-child(3) {
    transform: translate(-7px, -4px) rotate(45deg);
  }
}

.metalic-gradient {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-metalic-silver {
  background: rgb(82, 89, 125);
  transition: all 0.5s;
  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;

    background: linear-gradient(
      142deg,
      rgba(82, 89, 125, 1) 4%,
      rgba(94, 101, 143, 1) 17%,
      rgba(82, 93, 151, 1) 29%,
      rgba(133, 142, 190, 1) 44%,
      rgba(149, 162, 227, 1) 70%,
      rgba(108, 117, 165, 1) 85%,
      rgba(61, 67, 94, 1) 100%
    );
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 250%;
    height: 100%;
    background: linear-gradient(
      140deg,
      transparent 30%,
      rgb(255, 255, 255) 50%,
      transparent 70%
    );
    opacity: 0.1;
    transition: all 0.5s;
  }
  &:hover {
    &::after {
      left: 100%;
    }
  }
}

.bg-metalic-gold {
  background: rgb(236, 116, 0);
  background: linear-gradient(
    143deg,
    rgba(236, 116, 0, 1) 4%,
    rgba(242, 132, 0, 1) 20%,
    rgba(248, 178, 70, 1) 42%,
    rgba(245, 144, 0, 1) 57%,
    rgba(248, 178, 70, 1) 85%,
    rgba(236, 116, 0, 1) 100%
  );
}

.bg-metalic-gold {
  background: rgb(236, 116, 0);
  transition: all 0.5s;
  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;

    background: linear-gradient(
      143deg,
      rgba(236, 116, 0, 1) 4%,
      rgba(242, 132, 0, 1) 20%,
      rgba(248, 178, 70, 1) 42%,
      rgba(245, 144, 0, 1) 57%,
      rgba(248, 178, 70, 1) 85%,
      rgba(236, 116, 0, 1) 100%
    );
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 250%;
    height: 100%;
    background: linear-gradient(
      140deg,
      transparent 30%,
      rgb(255, 255, 255) 50%,
      transparent 70%
    );
    opacity: 0.1;

    transition: all 0.5s;
  }
  &:hover {
    &::after {
      left: 100%;
    }
  }
}

.correct-border {
  border: 5px solid rgba(146, 203, 247, 0.3);
  margin: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .unbreakable {
    page-break-inside: avoid;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
    page-break-after: always;
  }
}

@page {
  size: auto;
}
